import './css/App.css';
import logo from "./images/logo.png";
import denied from "./icons/alert.svg";
import items from "./pluggins/Buscador";
import qr from "qrcode";
import {useState} from "react";


async function getBuy(form=new FormData(), datas = []){
    var result = await fetch(items.url+"/fetchBuy",{
        method: 'POST',
        body: form
    }).then(resp=>{
        return resp.text()
    }).then(txt=>{
        console.log(txt)
        return JSON.parse(txt)
    })

    console.log(result)

    if (result!= null){
        setTimeout(() => {
            var canvas = document.querySelector("canvas");
            qr.toCanvas(canvas, JSON.stringify(datas), function (error) {
                if (error) console.error(error)
            })

            document.querySelector(".success").style.display= "unset"
            document.querySelector(".error").style.display= "none"
            document.querySelector(".propietario").textContent = result.comprador
            document.querySelector(".restantes").textContent = result.total - result.ocupados
        }, 1000)
    }else{
        setTimeout(()=>{
            document.querySelector(".success").style.display= "none"
            document.querySelector(".error").style.display= "unset"
            document.querySelector(".message").textContent="El correo proporcionado no pertenece a ninguna compra"
        },1000)
    }
}

function App() {

    var qr = require("qrcode");
    var url = window.location.href.split("/");


    if (url.length === 8) {
        var datas = {
            "event": url[3],
            "table": url[4],
            "min": url[5],
            "max": url[6],
            "email": url[7],
        }

        var form = new FormData();
        form.append("email",datas.email)
        form.append("event",datas.event)
        var buy = getBuy(form,datas)

    }else{
        setTimeout(()=>{
            document.querySelector(".success").style.display= "none"
            document.querySelector(".error").style.display= "unset"
            document.querySelector(".message").textContent="No se ha proporcionado ningun correo electrónico"
        },1000)
    }



  return (
    <div className="App">
        <div className="header">
            <img src={logo} alt=""/>
        </div>
        <div className="information">
            <h1>Cena en rojo</h1>
            <div className="date">Miercoles 20 de septiembre, 2023 - 19:00 hrs</div>
            <div className="location">Ex convento de San Hipólito</div>
            <div className="city">México, CDMX</div>
        </div>
        <div className="canvas-card">
            <div className="success">
                <h3>Presenta este QR en el evento</h3>
                <canvas/>
                <h3>Lugares restantes asignados a este QR: <b className={"restantes"}></b></h3>
                <h4>Tu lugar fue comprado por: <br/><br/> <b className={"propietario"}></b></h4>
            </div>
            <div className="error"  style={{display:"none"}}>
                <img src={denied} alt=""/>
                <h3>Acceso denegado</h3>
                <h2 className={"message"}>No encontramos correo electrónico valido.</h2>
                <h4>Verifica la url y vuelve a intentarlo</h4>
            </div>
        </div>
    </div>
  );
}

export default App;
